import './components/jquery.responsifyBgImg';
import './components/checkflexgap';
import {runAnimations} from './components/countup';
import './vendor/owl.carousel.min';
import lity from './vendor/lity.min';
//import './vendor/lity.min';

(function($) {

    setTimeout(function() { 
        runAnimations();
    }, 3000);
    
    const $body = $('body');
	  // Offcanvas

    $('.js-toggle-nav').on('click', () => {
      $body.toggleClass('menu-open');
    });

    $('.js-submenu').on('click', function() {
        var $this = $(this);

        $this.parent().toggleClass('submenu-open');
    });

    // Search toggle

    $('body').on('click', '.js-toggle-search', () => {
        $body.toggleClass('search-active');
    });

    // Owl Carousel

    const owlInternalSlider = $('.owl-internal-slider');

    const next = '<svg class="svgicon"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/wp-content/themes/iag/dist/img/icons.svg#next"></use></svg>';
    const prev = '<svg class="svgicon"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/wp-content/themes/iag/dist/img/icons.svg#prev"></use></svg>';


    owlInternalSlider.owlCarousel({
        loop: true,
        margin:0,
        nav:true,
        dots: true,
        autoplay: true,
        smartSpeed: 1000,
        autoplayTimeout: 10000,
        items:1,
        navText: [prev,next]
    });

    // Time of Day Greeting

    var thehours = new Date().getHours();
	var themessage;
	var morning = ('Good Morning');
	var afternoon = ('Good Afternoon');
	var evening = ('Good Evening');

	if (thehours >= 0 && thehours < 12) {
		themessage = morning; 

	} else if (thehours >= 12 && thehours < 17) {
		themessage = afternoon;

	} else if (thehours >= 17 && thehours < 24) {
		themessage = evening;
	}

    let $greeting_el = $('.site-header__greeting');

    if ($greeting_el.length > 0) {
        $greeting_el.append(themessage);
    }
	
    // Scroll to ID

    $('.js-scrollto').on('click', function () {
        var id = $(this).data('target');
        scrollTo(id);
    });

    function scrollTo(id){
        var el;
        var scrollPos;
        var winW = $(window).width();

        if (id === 'top') {
            scrollPos = 0;
        } else {
            el = $(id);
            scrollPos = el.offset().top;
        }

        $('html,body').animate({scrollTop: scrollPos},'slow');
    }

    // Responsive Background Images

    $('.js-responsive-bg').responsifybgimg();

	// Footcols

    $('.site-footer__column__heading').on('click', function () {
        $(this).parent('.site-footer__column').toggleClass('open');
    });

    const observer = new IntersectionObserver( 
        ([e]) => e.target.toggleAttribute('stuck', e.intersectionRatio < 1),
        {threshold: [1]}
    );

    observer.observe(document.getElementById('site-header'));

    // Key Docs Dropdown
    $(".js-keydocs").on('change', function () {
        let val = $(this).val();

        if (val.includes("youtube")) {
            if ($(this).val() != -1) {
                lity(val);
            } 
        } else {
            if ($(this).val() != -1) window.open($(this).val());
        }
    });

    $('.js-dropdown-list').on('click', function() {
        var $this = $(this);

        $this.next('.dropdown-list__content').slideToggle(300);

        $this.parent().toggleClass('dropdown-list__item--is-open');
    });
        
})(jQuery);